<template>
  <div class="JoinUsPart1">
    <div class="block_maintitle">为什么选择万鲸</div>
    <div class="core_content">
      <div
        v-for="(item, index) in JoinUsPart1_Data"
        :key="index"
        :class="{ core_li: true, core_li_active: activeKey === index }"
        @mouseenter="activeKey = index"
        :style="{
          backgroundImage: `url(${
            activeKey === index ? item.activeImage : item.backgroundImage
          })`,
        }"
      >
        <div class="core_li_item core_li_intro">{{ item.title }}</div>
        <div class="core_li_item core_li_detail">
          <img class="core_icon" :src="item.icon" alt="" />
          <div class="core_headline">{{ item.title }}</div>
          <div class="core_subtitle">{{ item.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JoinUsPart1_Data from "@/views/Protocol/JoinUs/Data/JoinUsPart1_Data";

/**
 * @author  XuHongli
 * @date  2022/12/27 14:42
 * @version 1.0
 * @description
 */
export default {
  name: "JoinUsPart1",
  data() {
    return {
      JoinUsPart1_Data,
      activeKey: "",
    };
  },
};
</script>

<style scoped lang="scss">
.block_maintitle {
  text-align: center;
  margin-bottom: 48px;
}
.JoinUsPart1 {
  padding: 53px 0;
  width: 100%;
}
.core_content {
  width: 1470px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  .core_li {
    width: 318px;
    height: 404px;
    position: relative;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    cursor: pointer;
    &.core_li_active {
      width: 456px;
      transition: all 0.4s;
      .core_li_detail {
        opacity: 1;
      }
      .core_li_intro {
        opacity: 0;
      }
    }
    .core_li_item {
      position: absolute;
    }
    .core_li_intro {
      left: 0;
      bottom: 0;
      width: 100%;
      background: #ff0001;
      opacity: 0.3;
      padding: 24px 30px;
      display: flex;
      justify-content: space-between;
      font-size: 21px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, fangsong;
      font-weight: 400;
      color: #fff;
      transition: all 0.2s ease-in-out;
    }
    .core_li_detail {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 0, 23, 0.78);
      padding: 48px 40px;
      opacity: 0;
      transition: all 0.4s ease 0.4s;
      .core_icon {
        width: 75px;
        height: 75px;
      }
      .core_headline {
        font-size: 26px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei, fangsong;
        font-weight: 400;
        color: #fff;
        margin: 18px 0 19px;
      }
      .core_subtitle {
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei, fangsong;
        font-weight: 400;
        color: #fff;
        line-height: 26px;
      }
    }
  }
}
</style>
