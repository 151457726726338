<template>
  <div class="joinUs">
    <div class="header_nav">
      <div class="head_title">
        <div class="head_title_top">加入我们</div>
        <div class="head_title_bottom">JOIN US</div>
        <div class="head_desc">
          <div class="head_desc_top">与<span>万鲸</span>一起</div>
          <div class="head_desc_bottom">做更好的自己</div>
        </div>
      </div>
    </div>
    <JoinUsPart1></JoinUsPart1>
  </div>
</template>

<script>
import JoinUsPart1 from '@/views/Protocol/JoinUs/JoinUsPart1'
/**
 * @author  XuHongli
 * @date  2022/12/27 14:28
 * @version 1.0
 * @description
 */
export default {
  name: 'JoinUs',
  components: {JoinUsPart1}
}
</script>

<style lang="scss" scoped>
.header_nav {
  background-image: url("https://resource.duobaoyu.com.cn/website/jrwm2_bj.png");
  position: relative;
  height: 542px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
  font-weight: 400;
  color: #fff;
  line-height: 1;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  .head_title {
    position: relative;
    font-size: 67px;
    padding: 200px 200px 200px 155px;
    width: 975px;

    &::before {
      position: absolute;
      left: 49%;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      display: block;
      width: 4px;
      height: 130px;
      background: #333;
    }

    .head_title_top {
      color: #333;
    }

    .head_title_bottom {
      margin-top: 5px;
    }

    .head_desc {
      position: absolute;
      top: 200px;
      left: 55%;
      font-size: 34px;
      margin-top: 19px;
      color: #333;

      .head_desc_bottom {
        margin-top: 20px;
      }
    }
  }
}
</style>
