/**
 * @author  XuHongli
 * @date  2022/12/27 14:51
 * @version 1.0
 * @description
 */

const JoinUsPart1_Data = [
  {
    backgroundImage: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Protocol/joinUs/joinUs_dby_001.png',
    activeImage: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Protocol/joinUs/joinUs_healthy_career_active.png',
    title: '健康的职涯发展',
    text: '通过纵向及横向发展通道，结合员工职业发展路径，让员工能力得到更聚焦更清晰的持续健康发展。',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Protocol/joinUs/product_healthy_career.png',
  },
  {
    backgroundImage: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Protocol/joinUs/joinUs_dby_002.png',
    activeImage: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Protocol/joinUs/joinUs_open_atmosphere_active.png',
    title: '开放的组织氛围',
    text: '以“简单、自由、平等”为核心的组织氛围，鼓励员工创新与建言献策，不断激发组织与个人的活力。',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Protocol/joinUs/product_open_atmosphere.png',
  },
  {
    backgroundImage: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Protocol/joinUs/joinUs_dby_003.png',
    activeImage: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Protocol/joinUs/joinUs_allRound_system_active.png',
    title: '全面的培养体系',
    text: '注重员工知识技能的培训，更重视员工能力和价值观的培养；能力是自己的，自觉主动利用所有可学习的机会发展自己。',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Protocol/joinUs/product_allRound_system.png',
  },
  {
    backgroundImage: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Protocol/joinUs/joinUs_dby_004.png',
    activeImage: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Protocol/joinUs/joinUs_rich_welfare_active.png',
    title: '丰厚的薪酬福利',
    text: '除了有竞争力的行业薪酬，我们还提供舒适宽松的工作环境，五险一金、交通补贴、通讯补贴以及13薪等各种福利。',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Protocol/joinUs/product_rich_welfare.png',
  }
]

export default JoinUsPart1_Data
